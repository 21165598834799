import React, { useState } from 'react'
import { gql } from '@apollo/client'

import { UserAvatar } from '../util/avatars'
import QueryList from '../lists/QueryList'
import UserPhoneNumberFilterContainer from './UserPhoneNumberFilterContainer'
import CreateFAB from '../lists/CreateFAB'
import SpeedDial from '../util/SpeedDial'
import UserCreateDialog from './UserCreateDialog'
import { useSessionInfo } from '../util/RequireConfig'
import UserOIDCCreateDialog from './UserOIDCCreateDialog'
import { GLASS_SUPER_ADMIN_EDIT_DELETE } from '../env'
import { setPageLoadToPharos } from '../actions'


const query = gql`
  query usersQuery($input: UserSearchOptions) {
    data: users(input: $input) {
      nodes {
        id
        name
        email
        isFavorite
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

function UserList(): JSX.Element {
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  //TLMT-3202
  const [showoidcCreateDialog,setoidcShowCreateDialog] = useState(false)
  const { isAdmin, ready, groups } = useSessionInfo()
    //TLMT-6319-start
    var obj = {'pageName':'userList','pageTitle':'userList'}
    setPageLoadToPharos(obj)
    //TLMT-6319-end
  //TLMT-3202
  let showCreateStatus = false
  if(groups && groups.length > 0) {
    if (groups.indexOf(GLASS_SUPER_ADMIN_EDIT_DELETE) !== -1) {
      showCreateStatus = true
    }
  }
  return (
    <React.Fragment>
      <QueryList
        query={query}
        variables={{ input: { favoritesFirst: true } }}
        mapDataNode={(n) => ({
          title: n.name,
          subText: n.email,
          url: n.id,
          isFavorite: n.isFavorite,
          icon: <UserAvatar userID={n.id} />,
        })}
        mapVariables={(vars) => {
          if (vars?.input.search.startsWith('phone=')) {
            vars.input.CMValue = vars.input.search.replace(/^phone=/, '')
            vars.input.search = ''
          }
          return vars
        }}
        searchAdornment={<UserPhoneNumberFilterContainer />}
      />
      {/* TLMT-3202 */}
      {ready && (isAdmin || showCreateStatus) && (
         <SpeedDial
         label='Add Items'
         actions={[
           {
             label: 'Add Basic User',
             icon: < UserAvatar userID={''} />,
             onClick: () => setShowCreateDialog(true),
           },
           {
             label: 'Add OIDC User',
             icon: < UserAvatar userID={''} />,
             onClick: () => setoidcShowCreateDialog(true),
           },
         ]}
       />
      )}
       {showCreateDialog && (
        <UserCreateDialog onClose={() => setShowCreateDialog(false)} />
      )}
      {showoidcCreateDialog && (
        <UserOIDCCreateDialog onClose={() => setoidcShowCreateDialog(false)} />
      )}
    </React.Fragment>
  )
}

export default UserList
