// TLMT-2713 changes
import React from 'react'
import { gql } from '@apollo/client'
import p from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { sortContactMethods } from './util'
import { Autocomplete } from '@mui/lab'
import Grid from '@mui/material/Grid'
export default function UserRoleSelect({
  userID,
  ...rest
}) {
    const RolesList = [{
            label: 'Individual Contributor',
            value: 'Individual Contributor'
        },{
            label: 'Manager',
            value: 'Manager'
        },{
            label: 'Director',
            value: 'Director'
        },{
            label: 'Vice President',
            value: 'Vice President'
        }]
  function renderControl() {
    return (
      <TextField select {...rest} style={{marginLeft: 5, width: 200}}>
        {RolesList.map((roleObj, key) => (
            <MenuItem key={key} value={roleObj.value}>
              {roleObj.label}
            </MenuItem>
          ))
        }
      </TextField>
    )
  }

  return (
    <React.Fragment>{renderControl()}</React.Fragment>
)
}

UserRoleSelect.propTypes = {
  userID: p.string.isRequired,
}