{/* TLMT-4864 - new file */}

import React from 'react'
import { gql, useMutation } from '@apollo/client'
import FormDialog from '../dialogs/FormDialog'


const mutation = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) { 
      createSubscription(input: $input) { 
        id 
        subscriptionID 
      } 
    } 
`

const deleteMutation = gql`
    mutation delete($input: [TargetInput!]!) { 
        deleteAll(input: $input) 
    }
`

interface UserCreateDialogProps {
  onClose: () => void
  values: any
}

function NotificationSubscriptionsDialog(props: UserCreateDialogProps): JSX.Element {
  
  const [updateSubscription, { loading, error }] = useMutation(mutation, {
    variables: {
      input: {
        id: props.values.id,
        subscriptionID: props.values.subscriptionID
      },
    },
    onCompleted: (data) => props.onClose(),
  })

  const [deleteSubscription] = useMutation(deleteMutation, {
    variables: {
      input: [{
        type: "notificationSubscription",
        id: props.values.mappingID
      }],
    },
    onCompleted: (data) => {
        props.onClose()
    },
  })

  

  let subText = "Are you sure to Subscribe?"
  if(props.values.isSubscribed) {
    subText = "Are you sure to Unsubscribe?"
  }

  return (
    <FormDialog
      title={props.values.title}
      loading={loading}
      //errors={nonFieldErrors(error)}
      onClose={props.onClose}
      onSubmit={() => {
            if(props.values.isSubscribed) 
                deleteSubscription()
            else
                updateSubscription()
        }
      }
      form={
        <div>{subText}</div>
      }
    />
  )
}

export default NotificationSubscriptionsDialog