import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { EscalationPolicySelect } from '../selection/EscalationPolicySelect'
import { FormContainer, FormField } from '../forms'
import { FieldError } from '../util/errutil'
import { useSessionInfo } from '../util/RequireConfig'
import MaterialAutoSelect from '../selection/MaterialAutoSelect'
import { sortArray } from '../util/funConfig'


export interface Value {
  name: string
  description: string
  escalationPolicyID?: string
  adgroup: string
}

interface ServiceFormProps {
  value: Value

  errors: FieldError[]

  onChange: (val: Value) => void

  disabled?: boolean

  epRequired?: boolean
}

export default function ServiceForm(props: ServiceFormProps): JSX.Element {
  const { groups } = useSessionInfo()
  
  const { epRequired, ...containerProps } = props
  return (
    <FormContainer {...containerProps} optionalLabels={epRequired}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Name'
            name='name'
            required
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Description'
            name='description'
            multiline
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Escalation Policy'
            name='escalation-policy'
            fieldName='escalationPolicyID'
            required={epRequired}
            component={EscalationPolicySelect}
          />
        </Grid>
        <Grid item xs={12}>
          {/* TLMT-3799 | AD group against the azure active directory - start  */}
        <FormField
            component={MaterialAutoSelect}
            fieldName='adgroup'
            fullWidth
            label='AD-Group'
            name='adgroup'
            options={sortArray(groups)}
          />
          {/* TLMT-3799 | AD group against the azure active directory - end  */}
        </Grid>
      </Grid>
    </FormContainer>
  )
}
