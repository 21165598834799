export * from './main'
export * from './auth'
export * from './hooks'
import Pharos from '@lowes/pharos'
import { PHAROS_ENABLE_STATUS, PHAROS_NPE_TOKEN, PHAROS_NPE_ENDPOINT, PHAROS_PROD_TOKEN, PHAROS_PROD_ENDPOINT } from '../util/constants'

//TLMT-6319-start
let origin =
        typeof window !== 'undefined' && window.location.origin
            ? window.location.origin
            : '';
let environment = 'dev'
if(origin === "https://glass.gcp-us-central1-glass-dev.carbon.lowes.com") {
  environment = 'dev'
} else if(origin === "https://glass-npe.lowes.com") {
    environment = 'stage'
} else if(origin === "https://glass.lowes.com") {
    environment = 'prod'
}

if(PHAROS_ENABLE_STATUS) {
  if(environment === 'prod'){
    Pharos.configure({
      token: PHAROS_PROD_TOKEN,
      endpoint: PHAROS_PROD_ENDPOINT
    });
  } else {
    Pharos.configure({ token: PHAROS_NPE_TOKEN, endpoint: PHAROS_NPE_ENDPOINT }); 
  }
}
//TLMT-6319-end