import React, { useState } from 'react'
import { useQuery, gql } from 'urql'
import _ from 'lodash'
import { Edit, Delete } from '@mui/icons-material'

import PolicyStepsQuery from './PolicyStepsQuery'
import PolicyDeleteDialog from './PolicyDeleteDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import CreateFAB from '../lists/CreateFAB'
import PolicyStepCreateDialog from './PolicyStepCreateDialog'
import DetailsPage from '../details/DetailsPage'
import PolicyEditDialog from './PolicyEditDialog'
import { GenericError, ObjectNotFound } from '../error-pages'
import Spinner from '../loading/components/Spinner'
import { EPAvatar } from '../util/avatars'
import { Redirect } from 'wouter'
import GroupSupervisors from '../details/GroupSupervisors'
import { useSessionInfo } from '../util/RequireConfig'
import { GLASS_SUPER_ADMIN_EDIT_DELETE } from '../env'
import { setPageLoadToPharos, useURLParam, useResetURLParams } from '../actions'

const query = gql`
  query ($id: ID!) {
    escalationPolicy(id: $id) {
      id
      name
      description
      adgroup
      permission

      notices {
        type
        message
        details
      }
    }
  }
`

export default function PolicyDetails(props: {
  policyID: string
}): JSX.Element {
  const stepNumParam = 'createStep'
  const [createStep, setCreateStep] = useURLParam<boolean>(stepNumParam, false)
  const resetCreateStep = useResetURLParams(stepNumParam)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  const [{ fetching, error, data: _data }] = useQuery({
    query,
    variables: {
      id: props.policyID,
    },
  })

  const data = _.get(_data, 'escalationPolicy', null)
 //TLMT-6319 - start
 var obj = {'pageName':'policyDetails','pageTitle':'policyDetails'}
 
  setPageLoadToPharos(obj)
  
  //TLMT-6319 - end
  if (!data && fetching) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!data) {
    return showDeleteDialog ? (
      <Redirect to='/escalation-policies' />
    ) : (
      <ObjectNotFound />
    )
  }
  //TLMT-3195 - Authorization
   const { groups } = useSessionInfo()
   var secondaryActions=[]
   var epStepCreate=false

   if (groups && groups.length > 0){
    if (groups.indexOf(GLASS_SUPER_ADMIN_EDIT_DELETE) !== -1){
      secondaryActions=[
        {
          label: 'Edit',
          icon: <Edit />,
          handleOnClick: () => setShowEditDialog(true),
        },
        {
          label: 'Delete',
          icon: <Delete />,
          handleOnClick: () => setShowDeleteDialog(true),
        },
        <QuerySetFavoriteButton
        key='secondary-action-favorite'
        type='escalationPolicy'
        id={data.id}
        />,
      ]
      epStepCreate=true
    }
else if  (data.permission === "editor"){
  secondaryActions=[
    {
      label: 'Edit',
      icon: <Edit />,
      handleOnClick: () => setShowEditDialog(true),
    },
    <QuerySetFavoriteButton
      key='secondary-action-favorite'
      type='escalationPolicy'
      id={data.id}
    />,
  ]
  epStepCreate=true
} else {
  secondaryActions=[
    <QuerySetFavoriteButton
        key='secondary-action-favorite'
        type='escalationPolicy'
        id={data.id}
    />,
  ]
  epStepCreate=false
} 
} 
else{
secondaryActions=[
  <QuerySetFavoriteButton
      key='secondary-action-favorite'
      type='escalationPolicy'
      id={data.id}
  />,
]
epStepCreate=false
}
//end

  return (
    <React.Fragment>
      <DetailsPage
        notices={data.notices}
        avatar={<EPAvatar />}
        title={data.name}
        details={data.description}
        pageContent={ <React.Fragment> <GroupSupervisors resource="escalation policy" adgroup={data.adgroup} /> <br/> <PolicyStepsQuery escalationPolicyID={data.id} /></React.Fragment> }
        secondaryActions={secondaryActions}
        links={[
          {
            label: 'Services',
            url: 'services',
            subText: 'Find services that link to this policy',
          },
        ]}
      />
      {epStepCreate && (<CreateFAB onClick={() => setCreateStep(true)} title='Create Step' />)}
      {createStep && (
        <PolicyStepCreateDialog
          escalationPolicyID={data.id}
          onClose={resetCreateStep}
        />
      )}
      {showEditDialog && (
        <PolicyEditDialog
          escalationPolicyID={data.id}
          onClose={() => setShowEditDialog(false)}
        />
      )}
      {showDeleteDialog && (
        <PolicyDeleteDialog
          escalationPolicyID={data.id}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </React.Fragment>
  )
}
