import React, { useState } from 'react'
import { gql, useQuery } from 'urql'
import { Redirect } from 'wouter'
import _ from 'lodash'
import { Button } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'

import DetailsPage, { LinkStatus } from '../details/DetailsPage'
import ServiceEditDialog from './ServiceEditDialog'
import ServiceDeleteDialog from './ServiceDeleteDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import ServiceOnCallList from './ServiceOnCallList'
import AppLink from '../util/AppLink'
import { ServiceAvatar } from '../util/avatars'
import ServiceMaintenanceModeDialog from './ServiceMaintenanceDialog'
import ServiceMaintenanceNotice from './ServiceMaintenanceNotice'
import { HeartbeatMonitor } from '../../schema'
import GroupSupervisors from '../details/GroupSupervisors'
import { useSessionInfo } from '../util/RequireConfig'
import { GLASS_SUPER_ADMIN_EDIT_DELETE } from '../env'
import { setPageLoadToPharos } from '../actions'

interface AlertNode {
  id: string
  status: string
}

const query = gql`
  fragment ServiceTitleQuery on Service {
    id
    name
    description
    adgroup
    permission
  }

  query serviceDetailsQuery($serviceID: ID!) {
    service(id: $serviceID) {
      ...ServiceTitleQuery
      maintenanceExpiresAt
      ep: escalationPolicy {
        id
        name
      }
      heartbeatMonitors {
        id
        lastState
      }
      onCallUsers {
        userID
        userName
        stepNumber
      }
    }

    alerts(
      input: {
        filterByStatus: [StatusAcknowledged, StatusUnacknowledged]
        filterByServiceID: [$serviceID]
        first: 1
      }
    ) {
      nodes {
        id
        status
      }
    }
  }
`

const hbStatus = (h: Partial<HeartbeatMonitor>[]): LinkStatus | null => {
  if (!h || !h.length) return null
  if (h.every((m) => m.lastState === 'healthy')) return 'ok'
  if (h.some((m) => m.lastState === 'unhealthy')) return 'err'
  return 'warn'
}

const alertStatus = (a: AlertNode[]): LinkStatus | null => {
  if (!a) return null
  if (!a.length) return 'ok'
  if (a[0].status === 'StatusUnacknowledged') return 'err'
  return 'warn'
}

export default function ServiceDetails(props: {
  serviceID: string
}): JSX.Element {
  const { serviceID } = props
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showMaintMode, setShowMaintMode] = useState(false)
  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { serviceID },
  })
  //TLMT-6319-start
  var obj = {'pageName':'serviceDetails','pageTitle':'serviceDetails'}
  setPageLoadToPharos(obj)
  //TLMT-6319-end
//TLMT-3195 - Authorization
const { groups } = useSessionInfo()
var secondaryActions=[]
var primaryActions=[]

  if (fetching && !_.get(data, 'service.id')) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!_.get(data, 'service.id')) {
    return showDelete ? <Redirect to='/services' /> : <ObjectNotFound />
  }

    //RBAC - TLMT-3810
    if (groups && groups.length > 0){
      if (groups.indexOf(GLASS_SUPER_ADMIN_EDIT_DELETE) !== -1){
        secondaryActions=[
          {
            label: 'Edit',
            icon: <Edit />,
            handleOnClick: () => setShowEdit(true),
          },
          {
            label: 'Delete',
            icon: <Delete />,
            handleOnClick: () => setShowDelete(true),
          },
          <QuerySetFavoriteButton
            key='secondary-action-favorite'
            id={serviceID}
            type='service'
          />,
        ]
         //TLMT-RBAC
primaryActions=[
          <Button
            color='primary'
            variant='contained'
            key='maintence-mode'
            onClick={() => setShowMaintMode(true)}
            aria-label='Maintenance Mode'
          >
            Maintenance Mode
          </Button>,
        ]
      } //else if (groups.indexOf(GLASS_GROUP_EDITOR) !== -1){
        else if  (_.get(data, 'service.permission') === "editor"){
          secondaryActions=[
            {
              label: 'Edit',
              icon: <Edit />,
              handleOnClick: () => setShowEdit(true),
            },
            <QuerySetFavoriteButton
              key='secondary-action-favorite'
              id={serviceID}
              type='service'
            />,
          ]
  
        } else {
          secondaryActions=[
            <QuerySetFavoriteButton
              key='secondary-action-favorite'
              id={serviceID}
              type='service'
            />,
          ]
        }  
       
    } else {
      secondaryActions=[
        <QuerySetFavoriteButton
          key='secondary-action-favorite'
          id={serviceID}
          type='service'
        />,
      ]
    }

  return (
    <React.Fragment>
      <DetailsPage
        avatar={<ServiceAvatar />}
        title={data.service.name}
        notices={<ServiceMaintenanceNotice serviceID={serviceID} />}
        subheader={
          <React.Fragment>
            Escalation Policy:{' '}
            {_.get(data, 'service.ep') ? (
              <AppLink to={`/escalation-policies/${data.service.ep.id}`}>
                {data.service.ep.name}
              </AppLink>
            ) : (
              <Spinner text='Looking up policy...' />
            )}
          </React.Fragment>
        }
        details={data.service.description}
        pageContent={ <React.Fragment> <GroupSupervisors resource="service" adgroup={data.service.adgroup} /> <br/> < ServiceOnCallList serviceID={serviceID} /></React.Fragment> }
         //TLMT-RBAC
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
        links={[
          {
            label: 'Alerts',
            status: alertStatus(data?.alerts?.nodes),
            url: 'alerts',
            subText: 'Manage alerts specific to this service',
          },
          {
            label: 'Heartbeat Monitors',
            url: 'heartbeat-monitors',
            status: hbStatus(data?.service?.heartbeatMonitors),
            subText: 'Manage endpoints monitored for you',
          },
          {
            label: 'Integration Keys',
            url: 'integration-keys',
            subText: 'Manage keys used to create alerts',
          },
          {
            label: 'Labels',
            url: 'labels',
            subText: 'Group together services',
          },
          {
            label: 'Alert Metrics',
            url: 'alert-metrics',
            subText: 'Review alert activity',
          },
        ]}
      />
      {showEdit && (
        <ServiceEditDialog
          onClose={() => setShowEdit(false)}
          serviceID={serviceID}
        />
      )}
      {showDelete && (
        <ServiceDeleteDialog
          onClose={() => setShowDelete(false)}
          serviceID={serviceID}
        />
      )}
      {showMaintMode && (
        <ServiceMaintenanceModeDialog
          onClose={() => setShowMaintMode(false)}
          serviceID={serviceID}
          expiresAt={data.service.maintenanceExpiresAt}
        />
      )}
    </React.Fragment>
  )
}
