//TLMT-3202
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { fieldErrors, nonFieldErrors } from '../util/errutil'
import FormDialog from '../dialogs/FormDialog'
import { FormContainer, FormField } from '../forms'
import { useConfigValue } from '../util/RequireConfig'
import axios from 'axios'
import { axiosApiRoot } from '../env'
import FormHelperText from '@mui/material/FormHelperText'
import { Search as SearchIcon } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid, TextField } from '@mui/material'
import { useLocation } from 'wouter'

const mutation = gql`
  mutation ($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`
interface UserCreateDialogProps {
  onClose: () => void
}

function UserCreateDialog(props: UserCreateDialogProps): JSX.Element {
  const [value, setValue] = useState({
    username: '',
    email: '',
    isAdmin: false,
    name: '',
    salesID: '',
    type: 'oidc',
  })
  const [apiError, setApiError] = useState('')
  const [, navigate] = useLocation()
  const [authDisableBasic] = useConfigValue('Auth.DisableBasic')
  const [createUser, { loading, error }] = useMutation(mutation, {
    variables: {
      input: {
        username: value.username,
        name: value.name ? value.name : null,
        email: value.email,
        role: value.isAdmin ? 'admin' : 'user',
        favorite: true,
        salesID: value.salesID,
        type: 'oidc'
      },
    },
    onCompleted: (data) => navigate(`/users/${data.createUser.id}`),
  })
  
  const getFieldValue = (value: any) => {
    if(value || value !== null) {
      return value
    }
    return ''
  }

  const getUserDetails = async(retValue: any) => {
    //const len = parseInt(Math.log10(retValue) + 1)  
    //if(len >= 7) {
      //const getUrl = axiosApiRoot+'/api/v1/integration/userdetails?employeeId='+retValue


      const getUrl = axiosApiRoot+'/api/v1/azureadutils/userdetails?employeeId='+retValue
      const response = await axios.get(getUrl).then(
        resp => {
          return resp.data
        }
      ).catch((error: any) => {
        console.log("Axios Error ", error.toJSON())
      }) 
      if(response) {
        const updatedValue = {
          ...value,
          salesID: retValue,
          name: getFieldValue(response.displayName),
          username: getFieldValue(response.userPrincipalName),
          email: getFieldValue(response.mail)
        }
        setValue(updatedValue)
        if(response.displayName === null) {
          setApiError('User details not found, please try with another sales id')
        } else {
          setApiError('')
        }
      } else {
        const updatedValueSalesID = {
          ...value,
          salesID: retValue,
        }
        setValue(updatedValueSalesID)
        setApiError('User details not found, please try with another sales id')
      }
    /*} else {
      const updatedValueSalesID = {
        ...value,
        salesID: retValue,
      }
      setValue(updatedValueSalesID)
    } */  
  }


  return (
    <React.Fragment>
      <div>Top Error come shere</div>
      <FormDialog
        title='Create User'
        loading={loading}
        errors={nonFieldErrors(error)}
        onClose={props.onClose}
        onSubmit={() => createUser()}
        notices={
          authDisableBasic
            ? [
                {
                  type: 'WARNING',
                  message: 'Basic Auth is Disabled',
                  details:
                    'This user will be unable to log in until basic auth is enabled.',
                },
              ]
            : []
        }
        form={
          <FormContainer
            value={value}
            errors={fieldErrors(error)}
            /*onChange={(val: typeof value) => {
                const updatedValue = {
                  ...value,
                  salesId: val
                }
                setValue(updatedValue)
              }
            }*/
            onChange={(val: typeof value) => {
                setValue(val)
              }
            }
            optionalLabels
          >
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormField
                  fullWidth
                  component={TextField}
                  name='salesID'
                  label='Sales ID'
                  // mapOnChangeValue={(value) => getUserDetails(value)}
                  // // mapOnChangeValue={(val) => {
                  // //     //getUserDetails(val)
                  // //   }
                  // // }
                  mapOnBlurValue = {(val) => getUserDetails(val)
                  }
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                   }}
                  />
                  {apiError && 
                    <FormHelperText>
                      <span style={{color: 'red'}}>{apiError}</span>
                    </FormHelperText>
                  }
              </Grid>
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  component={TextField}
                  name='username'
                  required
                />
              </Grid>              
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  component={TextField}
                  name='name'
                  label='Display Name'
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  component={TextField}
                  name='email'
                  type='email'
                />
              </Grid>
              {/*<Grid item xs={12}>
                <FormControlLabel
                  control={
                    <FormField component={Checkbox} checkbox name='isAdmin' />
                  }
                  label='Admin'
                  labelPlacement='end'
                />
                </Grid>*/}
            </Grid>
          </FormContainer>
        }
      />
      <div>Error come shere</div>
    </React.Fragment>
  )
}

export default UserCreateDialog
