import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { EscalationPolicySelect } from '../../../selection/EscalationPolicySelect'
import { FormContainer, FormField } from '../../../forms'
import { FieldError } from '../../../util/errutil'
import { ServiceSelect } from '../../../selection'
import MaterialAutoSelect from '../../../selection/MaterialAutoSelect'
import { sortArray } from '../../../util/funConfig'
import { useSessionInfo } from '../../../util/RequireConfig'



export interface Value {
  name: string
  description: string
  escalationPolicyID?: string
}

interface SuperServiceFormProps {
  value: Value

  errors: FieldError[]

  onChange: (val: Value) => void

  disabled?: boolean

}

export default function SuperGroupForm(props: SuperServiceFormProps): JSX.Element {
  const {  ...containerProps } = props
  const { groups } = useSessionInfo()

  return (
    <FormContainer {...containerProps}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Name'
            name='name'
            required
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Description'
            name='description'
            multiline
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            label='Services'
            name='services'
            component={ServiceSelect}
            required
            multiple
          />
        </Grid>
        <Grid item xs={12}>
            {/* TLMT-3799 | AD group against the azure active directory - start  */}
          <FormField
            component={MaterialAutoSelect}
            fieldName='adgroup'
            fullWidth
            label='AD-Group'
            name='adgroup'
            options={sortArray(groups)}
          />
          {/* TLMT-3799 | AD group against the azure active directory - end  */}
          </Grid>
      </Grid>
    </FormContainer>
  )
}
