import React, { ComponentType, useState } from 'react'
import QueryList, { QueryListProps } from './QueryList'
import CreateFAB from './CreateFAB'
interface SimpleListPageProps extends QueryListProps {
  createLabel?: string
  //TLMT - RBAC
  hideCreate: boolean
  createDialogComponent?: ComponentType<{ onClose: () => void }>
}

export default function SimpleListPage(
  props: SimpleListPageProps,
): JSX.Element {
  //TLMT-RBAC
  const { createDialogComponent: DialogComponent, createLabel,hideCreate, ...rest } = props
  const [create, setCreate] = useState(false)

  return (
    <React.Fragment>
      <QueryList {...rest} />
      {/* TLMT-RBAC */}
      {!hideCreate &&
        <React.Fragment>
      {createLabel && (
        <CreateFAB
          onClick={() => setCreate(true)}
          title={`Create ${createLabel}`}
        />
      )}
      {create && DialogComponent && (
        <DialogComponent onClose={() => setCreate(false)} />
      )}
        </React.Fragment>
      }
    </React.Fragment>
  )
}
