export const POP_STATUS = 'POP_STATUS';
export const LOADER_STATUS = 'LOADER_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const ERROR_STATUS = 'ERROR_STATUS';
export const PAAS_NOTIFICATION = 'PAAS_NOTIFICATION'
export const PAAS_OPTIONS_REMOVE = 'PAAS_OPTIONS_REMOVE'
export const CHECK_AVAILABILITY_REMOVE = 'CHECK_AVAILABILITY_REMOVE'
export const INVALID_TOKEN = 'INVALID_TOKEN'

export const TEMPLATES_LIST = 'TEMPLATES_LIST'
export const ALERTS_LIST = 'ALERTS_LIST'
export const MSTEAM_DATA = 'MSTEAM_DATA'
export const TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW'
//TLMT-6465
export const ENGAGEMENT_TYPE= 'ENGAGEMENT_TYPE'