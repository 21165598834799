import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIcon from '@mui/icons-material/Phone';
//import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteIcon from '@mui/icons-material/Favorite';
//import PersonPinIcon from '@material-ui/icons/PersonPin';
import PersonPinIcon from '@mui/icons-material/PersonPin';
//import HelpIcon from '@material-ui/icons/Help';
import HelpIcon from '@mui/icons-material/Help';
//import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
//import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
//import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
}));

export default function PaaSTabs({ tabs, curTab, tabsCB }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabsCB(newValue)
  };

  useEffect(() => {
    setValue(curTab);
  }, [curTab])

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
            {tabs.map((tabObj, key) => {
                return(
                    <Tab key={key} label={tabObj.name} icon={tabObj.icon} {...a11yProps(key)} />
                )
            })}          
        </Tabs>
      </AppBar>
        {tabs.map((tabObj, key) => {
            return(
                <TabPanel value={value} index={key}>
                    {tabObj.content}
                </TabPanel>
            )
        })} 
    </div>
  );
}