//TLMT-6465-START
import { getActionStore } from '../../action/formActions'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux';
import React, { useMemo, useState, useEffect}  from 'react'
import { Card, CardContent, CardHeader, Grid,
 Select,
 InputLabel,
 MenuItem,
 FormControl,
 SelectChangeEvent } from '@mui/material'
import { DateTime, DateTimeUnit, Duration, Interval } from 'luxon'
import { useURLParam } from '../../../actions'
import AlertMetricsFilter from '../../../services/AlertMetrics/AlertMetricsFilter'
import AlertCountGraph from '../../../services/AlertMetrics/AlertCountGraph'
import AlertMetricsTable from '../../../services/AlertMetrics/AlertMetricsTable'
import AlertAveragesGraph from '../../../services/AlertMetrics/AlertAveragesGraph'
import { GenericError,ObjectNotFound } from '../../../error-pages'
import { useWorker } from '../../../worker'
import { AlertMetricsOpts } from '../../../services/AlertMetrics/useAlertMetrics'
import { useAlerts } from '../../../services/AlertMetrics/useAlerts'
import { useQuery } from 'urql'
import Spinner from '../../../loading/components/Spinner'
import AlertSearch from './AlertSearch'
import { AlertSearchOptions } from '../../../../schema'
import makeStyles from '@mui/styles/makeStyles'


const units: Record<string, DateTimeUnit> = {
 P1D: 'day',
 P1W: 'week',
 P1M: 'month',
}
const useStyles = makeStyles((theme: Theme) => ({
heading: {
   fontSize: '1.1rem',
   flexBasis: '33.33%',
   flexShrink: 0,
 },
}))
export default function EngagementType(): JSX.Element {
const classes = useStyles()
const now = useMemo(() => DateTime.now(), [])
const [range] = useURLParam('range', 'P1M')
const [ivl] = useURLParam('interval', 'P1D')
const [type, setType] = useState('Select template type')
const graphDur = Duration.fromISO(ivl).toISO()
const unit = units[ivl]
const since = now.minus(Duration.fromISO(range)).startOf(unit)
const until = now.startOf(unit)
const dispatch = useDispatch();
const [events, setEvents] = useState([]);
const [depKey, setDepKey] = useState(`${since}-${until}`)
const [alertOptions, setAlertOptions] = useState({})

const getAlertsList = (type:string) => {
const getApiUrl = `/api/v1/templates/goAlertIds/${type}`; 
dispatch(getActionStore(getApiUrl, 'ENGAGEMENT_TYPE', {}, {}, '', history,));
};
useEffect(() => {
 getAlertsList(type);
}, [type]);

useEffect(() => {
 if (since && until) {
   setDepKey(`${since}-${until}`);
 }
}, [since, until]);
const templatesData = useSelector(state => state.templates);
useEffect(() => {
 setEvents(templatesData.engagementType);
 
}, [templatesData.engagementType]);

useEffect(()=>{
 setAlertOptions({
   filterByAlertIds: events,
   //filterByServiceID: events,
   filterByStatus: ['StatusClosed'],
   notClosedBefore: since.toISO(),
   closedBefore: until.toISO(),
 })
},[events])
 alertOptions.filterByAlertIds = events
 alertOptions.filterByStatus = ['StatusClosed']
 alertOptions.closedBefore = until.toISO()
 alertOptions.notClosedBefore = since.toISO()
 const alertsData = useAlerts(alertOptions, depKey,false,true)
 const graphInterval = Interval.fromDateTimes(since, until).toISO()

 const metricsOpts: AlertMetricsOpts = useMemo(
   () => ({ int: graphInterval, dur: graphDur, alerts: alertsData.alerts }),
   [graphInterval, graphDur, alertsData.alerts],
 )
 const [graphData, graphDataStatus] = useWorker(
   'useAlertMetrics',
   metricsOpts,
   [],
 )

 if (alertsData.error) {
   return <GenericError error={alertsData.error.message} />
 }

 const dayCount = Math.ceil(until.diff(since, unit).as(unit))
 return (
   
   <Grid container spacing={2}>
     <Grid item xs={12}>
       <Card>
         {type === 'Select template type'?<CardHeader
           paddingLeft={2}
           component='h2'
         />:<CardHeader
         paddingLeft={2}
         component='h2'
         title={`Alert metrics over the past ${dayCount} ${unit}s for ${type}`}
       />}
         <Grid container sx={{ marginLeft: '3rem' }}>
           <Grid item xs={5} paddingLeft={2}>
         <FormControl sx={{ width: '130%' }}>
         <InputLabel id='demo-simple-select-helper-label'>
           Type
         </InputLabel>
         <Select
           fullWidth
           value={type}
           label='Type'
           name='type-title'
           onChange={(e: SelectChangeEvent<string>) => {
             setType(e.target.value)
             getAlertsList(e.target.value)
           }
           }
         >
         <MenuItem value='Select template type'>Select template type</MenuItem> 
           <MenuItem value='TOC - Technology Operations Center'>TOC - Technology Operations Center</MenuItem>
           <MenuItem value='IC - Incident Command'>IC - Incident Command</MenuItem>
           <MenuItem value='ERE Workflow'>ERE Workflow</MenuItem>
           <MenuItem value='ITSD - IT Service Desk'>ITSD - IT Service Desk</MenuItem>

       
         </Select>
       </FormControl>
       </Grid>
       <Grid item xs={5} paddingRight={1}>
       <AlertSearch
             type="engagement-type"
           />
          </Grid>
       </Grid>

         <CardContent>
         {type !== 'Select template type'?
         <>
           <AlertMetricsFilter />
         <AlertCountGraph
             data={graphData}
             loading={graphDataStatus.loading || alertsData.loading}
           />
           <AlertAveragesGraph
             data={graphData}
             loading={graphDataStatus.loading || alertsData.loading}
           />
           </>:  <Grid container sx={{ marginLeft: '3rem' }}>
           <Grid item xs={5} paddingLeft={1}><Typography
               component='h1'
               variant='heading'
               className={classes.heading}
             >Please select a template type from the Type dropdown</Typography></Grid></Grid>}
         </CardContent>
       </Card>
     </Grid>
   </Grid>
   
 )
}
//TLMT-6465-END