import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import Favorite from '@material-ui/icons/Favorite';
import FavoriteIcon from '@mui/icons-material/Favorite';
//import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { makeStyles } from '@mui/styles';
import InfoTooltips from './infoTooltip'

const useStyles = makeStyles((theme) => ({
    checkGroup: {
        marginLeft: 10
    },
    ErrorBlock: {
      color: '#ff0000'
    },
    helperText: {
      margin: '0 !important'
    },
    FormWrapper: {
      padding: 10,
      display: 'flex'
    },
    FormControlStyle: {
        width: '60%'
    },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function PaaSCheckBox({formDetails, onChecked, originalData, currentValue, errors}) {
  
    let objArr = {}
    formDetails.options.map((opt) => {
        //const checkStatus = (formDetails.preSelected.indexOf(opt.value) !== -1) ? true : false
        if(formDetails.preSelected)
        {
          Object.keys(formDetails.preSelected).map((opt) => {
            objArr[opt] = formDetails.preSelected[opt]
          })
          //objArr[opt.value] = checkStatus
        } 
    })
  const [state, setState] = React.useState(objArr);
  
  useEffect(()=>{
    setState(currentValue)
  },[currentValue])
  
  const handleChange = (event) => {
      let updatedState = state
      if(!updatedState){
        updatedState={}
        updatedState.selected = true

      } else {
        updatedState[event.target.name] = event.target.checked
      }
    onChecked(updatedState)
    setState(updatedState)
  };

  const classes = useStyles();

  return (
    <div>
      <div className={classes.FormWrapper}>
        <div className={classes.FormControlStyle}>
          <FormGroup row className={classes.checkGroup}>
            {formDetails.options.map((opt, key) => {
              let dispStatus = false
              if(state[opt.value]) {
                dispStatus = state[opt.value]
              }                
              return (
                <FormControlLabel
                  control={<Checkbox color="default" defaultChecked={dispStatus} checked={dispStatus} onChange={handleChange} name={opt.value} />}
                  label={opt.label}
                />)
            })}
          </FormGroup>
        </div>
        <InfoTooltips info={formDetails.info} originalData={originalData} />    
      </div>
      {(errors && errors[formDetails.name]) && 
        <FormHelperText className={classes.helperText}>
            <div className={classes.ErrorBlock}>{errors[formDetails.name]}</div>
        </FormHelperText>
      }
    </div>
  );
}