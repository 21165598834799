import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

// function Alert(props) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaasSnackbar({openStatus, severity, message, closeCB}) {
  const classes = useStyles();
  /*const [open, setOpen] = React.useState(openStatus);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };*/

  return (
    <div className={classes.root}>
      {/*<Button variant="outlined" onClick={handleClick}>
        Open success snackbar
    </Button>*/}
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openStatus} autoHideDuration={6000} onClose={closeCB}>
        <Alert elevation={6} variant="filled" onClose={closeCB} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {/*<Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert>*/}
    </div>
  );
}
