// TLMT-2713 changes
import React from 'react'
import { gql } from '@apollo/client'
import p from 'prop-types'
import { Mutation } from '@apollo/client/react/components'
import UserRoleSelect from './UserRoleSelect'

const mutation = gql`
    mutation updatePagingRole($input: UpdateUserPagingRole!){ 
        updatePagingRole(input: $input)
    }
`

export default function UserRoleUpdatePreference({ userID, data }) {
  function renderControl(pagingrole, updateCM) {
    return (
      <UserRoleSelect
        userID={userID}
        label='User Role Update'
        name='alert-status-contact-method'
        value={pagingrole}
        onChange={updateCM}
      />
    )
  }

  function renderMutation(user) {
    const setCM = (commit) => (e) => {
      commit({
        operationName: "updatePagingRole",
        variables: {
            input: {
                id: userID,
                pagingrole: e.target.value
            }
        }},)
    }
    return (
      <Mutation mutation={mutation}>
        {(commit) =>
          renderControl(user.pagingrole, setCM(commit))
        }
      </Mutation>
    )
  }

  return (
      <React.Fragment>{renderMutation(data.user)}</React.Fragment>
  )
}

UserRoleUpdatePreference.propTypes = {
  userID: p.string.isRequired,
}