{/* TLMT-4864 - new file */}

import React, {useState} from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Card, IconButton, Tooltip } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlatList from '../lists/FlatList'
//import { sortBy, values } from 'lodash'
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import NotificationSubscriptionsDialog from './NotificationSubscriptionsDialog';

const query = gql`
  query listsubscription($userId: ID!) {
    listsubscription(userId: $userId) {
      id
      name
      mappingID
      isSubscribed
    }
  }
`

const createmMutation = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) { 
      createSubscription(input: $input) { 
        id 
        subscriptionID 
      } 
    } 
`

const deleteMutation = gql`
    mutation delete($input: [TargetInput!]!) { 
        deleteAll(input: $input) 
    }
`

interface QueryResult {
  listsubscription: {
    id: string
    name: string
    isSubscribed: boolean
  }
}




export default function NotificationSubscriptions(props: {
  userID: string
  currentUser?: boolean
  pageName: string
  pageTitle: string
}): JSX.Element {
  const { data, loading, error } = useQuery(query, {
    variables: { userId: props.userID },
  })

  const [value, setValue] = useState({
    id: '',
    subscriptionID: '',
    mappingID: ''
  })

  const [showDialog, setShowDialog] = useState(false)

  const [updateSubscription] = useMutation(
    createmMutation,
    {
      variables: { 
        input: {
          id: props.userID,
          subscriptionID: value.subscriptionID
        }
      }
    }
  )

  const [deleteSubscription] = useMutation(deleteMutation, {
    variables: {
      input: [{
        type: "notificationSubscription",
        id: value.mappingID
      }],
    }
  })

  if (!data && loading) {
    return <Spinner />
  }
  if (error) {
    return <GenericError error={error.message} />
  }
  if (!data?.listsubscription) {
    return <ObjectNotFound />
  }

  const listsubscription = (data as QueryResult).listsubscription
    

  return (
    <Card>
      <FlatList
        headerNote={'Notification Subscriptions'}
        emptyMessage={'No Subscfriptions'}
        items={listsubscription.map((itemObj: any) => {
          return ({
            title: itemObj.name,
            //url: '/services/' + itemObj.id,
            //subText: itemObj.subText,
              secondaryAction: (
                <Tooltip title={itemObj.isSubscribed ? 'Click to Unsubscribe' : 'Click to Subscribe'}>
                  <IconButton
                    //color= {itemObj.isSubscribed ? 'success' : 'error'}
                    onClick={() => {
                        const setObj = {
                          id: props.userID,
                          subscriptionID: itemObj.id,
                          title: itemObj.name,
                          isSubscribed: itemObj.isSubscribed,
                          mappingID: itemObj.mappingID
                        }
                        setValue(setObj)
                        //setShowDialog(true)
                        setTimeout(() => {
                          if(!itemObj.isSubscribed) {
                            updateSubscription()
                          } else {
                            deleteSubscription()
                          }
                        }, 300)
                      }
                    }
                  >
                    {itemObj.isSubscribed ? (
                      <FavoriteIcon style= {{color: '#cd1831'}} />
                    ) : (
                      <FavoriteBorderIcon />
                    )}                  
                  </IconButton>
                </Tooltip>
              ),
          })
        }
        )}
      />
      {showDialog && (
        <NotificationSubscriptionsDialog onClose={() => setShowDialog(false)} values={value} />
      )}
    </Card>
  )
}
