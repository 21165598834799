import React, { MouseEventHandler, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button, { ButtonProps } from '@mui/material/Button'
import MUICardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useMutation } from '@apollo/client'
import {gql, useQuery} from 'urql'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete } from '@mui/lab'
import TextField from "@mui/material/TextField";
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import _ from 'lodash'

interface CardActionProps {
  primaryActions?: Array<Action | JSX.Element>
  secondaryActions?: Array<Action | JSX.Element>
}

interface ActionProps {
  action: Action
  secondary?: boolean // if true, renders right-aligned as an icon button
}

export type Action = {
  label: string // primary button text, use for a tooltip if secondary action
  handleOnClick: MouseEventHandler<HTMLButtonElement>
  icon?: JSX.Element // if true, adds a start icon to a button with text
  meta?: string
  ButtonProps?: ButtonProps
}

const useStyles = makeStyles({
  cardActions: {
    alignItems: 'flex-end', // aligns icon buttons to bottom of container
  },
  primaryActionsContainer: {
    padding: 8,
  },
  autoExpandWidth: {
    margin: '0 auto',
  },
})
const query = gql`
query getAlert($alertId: Int!) {
  escSteps(id: $alertId) {
      currentStep
      totalSteps
  }
}
`
export default function CardActions(p: CardActionProps): JSX.Element {
  const classes = useStyles()

  const action = (
    action: Action | JSX.Element,
    key: string,
    secondary?: boolean,
  ): JSX.Element => {
    if ('label' in action && 'handleOnClick' in action) {
      return <Action key={key} action={action} secondary={secondary} />
    }
    return action
  }

  let actions: Array<JSX.Element> = []
  if (p.primaryActions) {
    actions = [
      <div
        key='primary-actions-container'
        className={classes.primaryActionsContainer}
      >
        {p.primaryActions.map((a, i) => action(a, 'primary' + i))}
      </div>,
    ]
  }
  if (p.secondaryActions) {
    actions = [
      ...actions,
      <div key='actions-margin' className={classes.autoExpandWidth} />,
      ...p.secondaryActions.map((a, i) => action(a, 'secondary' + i, true)),
    ]
  }

  return (
    <MUICardActions data-cy='card-actions' className={classes.cardActions}>
      {actions}
    </MUICardActions>
  )
}

function Action(p: ActionProps): JSX.Element {
  const { action, secondary } = p
  const [open, setOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('')
  const [selectedStep,setSelectedStep] = useState({step: ''})
  const [activeStep, setActiveStep] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)
//   const [opt,setOpt] = useState([])
  // const useStyles = makeStyles({
  //   dialog: {
  //     minHeight: '200px',
  //     width:'50%'
  //   },
  // })
  // const classes = useStyles()
  const updateStatusMutation = gql`
  mutation UpdateAlertsMutation($input: UpdateAlertsInput!) {
    updateAlerts(input: $input) {
      id
    }
  }
`
  const escAlertsMutation = gql`
mutation EscalateAlertToASpecificStepMutation($input: EscalateAlertToASpecificStepInput!) {
  escalateAlertToASpecificStep(input: $input) {
    id
  }
}
`

const handleOpen = () => {
  setOpen(true);
}

const handleClose = () => {
  setOpen(false);
};
  var options = [];
  var alertId;
  const [errorr, setErrorr] = useState('')
  if(action && action.meta){
    alertId = action.meta
  } else{
    alertId = 0
  }
  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { alertId },
  })

  console.log("data", error)

  //if (fetching && !_.get(data, 'escSteps.currentStep')) return <Spinner />
  //if (error) return <GenericError error={error.message} />

  if(!error && data && data.escSteps){
  for(var i=0;i<data.escSteps.totalSteps;i++){
    if(i>data.escSteps.currentStep){
      const step = i+1
      options.push(step.toString())
    }
  }
}  
const [close] = useMutation(updateStatusMutation, {
  variables: {
    input: {
      alertIDs: [action.meta],
      closeMessage: textFieldValue,
      newStatus: 'StatusClosed',
    },
  },
})

var ss = Number(selectedStep.step)
  const [esc] = useMutation(escAlertsMutation, {
    variables: {
      input: {
        id: action.meta,
        step: +Number(selectedStep.step)-1
      },
    },
  })
  const validate = (message:string)=>{
    if(message.length>250){
      setErrorr('Cannot be more than 250 characters')
      return false
    }
      return true
  }
  const validateStep = (step:string)=>{
    if(step === ''){
      setErrorr('Please select a step')
      return false
    }
      return true
  }
  //TLMT-4094
  if(action.label === 'Close'){
    return (
      <>
    <Tooltip title={action.label} placement='top'>
      <IconButton onClick={handleOpen}>{action.icon}</IconButton>
    </Tooltip>
      <Dialog open={open} fullWidth
  maxWidth="sm">
      <DialogTitle>Resolution Message</DialogTitle>
      <DialogContent>
      <TextField
        fullWidth
        autoFocus
        id="closeMessage"
        label="Resolution Message"
        variant="standard"
        onChange={(e) => {setTextFieldValue(e.target.value);setErrorr('')}}
        helperText={error}
        error = {error}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick= {()=>{
        if(validate(textFieldValue)){
          close()
        }
      }}>Submit</Button>
    </DialogActions>
  </Dialog>
  </>
  )
  } else if (!error && data && data.escSteps && +data.escSteps.currentStep+1 <= +data.escSteps.totalSteps-2 && action.label == 'Escalate'){
    return (
        <>
       <Tooltip title={action.label} placement='top'>
        <IconButton onClick={handleOpen}>{action.icon}</IconButton>
      </Tooltip>
        <Dialog open={open} fullWidth
  maxWidth="sm">
        <DialogTitle>Escalate to a specific step</DialogTitle>
        <DialogContent>
        <Autocomplete
            // disablePortal
            id="combo-box-demo"
            options={options}
            onChange={(e,value) => {setSelectedStep({ step: value});setErrorr('')}}
            renderInput={(params) => <TextField {...params} label="Escalate to step"         
 
            />}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick= {()=>{
            if(validateStep(selectedStep.step)){
              esc().then(()=>{
                setSelectedStep({ step: ''})
                setErrorr('')
              })
              setOpen(false)
            }
        }}>Submit</Button>
      </DialogActions>
      
    </Dialog>
    </>
    )
  } else if (secondary && action.icon) {
    // wrapping button in span so tooltip can still
    // render when hovering over disabled buttons
    return (
      <Tooltip title={action.label} placement='top'>
        <span aria-label={undefined}>
          <IconButton
            aria-label={action.label}
            onClick={action.handleOnClick}
            size='large'
            {...action.ButtonProps}
          >
            {action.icon}
          </IconButton>
        </span>
      </Tooltip>
    )

    }

  return (
    <Button
      onClick={action.handleOnClick}
      startIcon={action.icon}
      variant='contained'
      {...action.ButtonProps}
    >
      {action.label}
    </Button>
  )
}
