import React, { useState } from 'react'
import { gql, useQuery } from 'urql'
import _ from 'lodash'
import { Redirect } from 'wouter'
import { Edit, Delete } from '@mui/icons-material'
import GroupSupervisors from '../details/GroupSupervisors'
import CreateFAB from '../lists/CreateFAB'
import { handoffSummary } from './util'
import DetailsPage from '../details/DetailsPage'
import RotationEditDialog from './RotationEditDialog'
import RotationDeleteDialog from './RotationDeleteDialog'
import RotationUserList from './RotationUserList'
import RotationAddUserDialog from './RotationAddUserDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import Spinner from '../loading/components/Spinner'
import { ObjectNotFound, GenericError } from '../error-pages'
import { RotationAvatar } from '../util/avatars'
import { useSessionInfo } from '../util/RequireConfig'
import { GLASS_SUPER_ADMIN_EDIT_DELETE } from '../env'
import { setPageLoadToPharos } from '../actions'

const query = gql`
  fragment RotationTitleQuery on Rotation {
    id
    name
    adgroup
    permission
    description
  }

  query rotationDetails($id: ID!) {
    rotation(id: $id) {
      ...RotationTitleQuery

      activeUserIndex
      userIDs
      type
      shiftLength
      timeZone
      start
    }
  }
`

export default function RotationDetails(props: {
  rotationID: string
}): JSX.Element {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)
  //TLMT-6319 - start
  var obj = {'pageName':'rotationDetails','pageTitle':'rotationDetails'}
  
    setPageLoadToPharos(obj)
  
  //TLMT-6319 - end  

  const [{ data: _data, fetching, error }] = useQuery({
    query,
    variables: { id: props.rotationID },
  })

  const data = _.get(_data, 'rotation', null)

  if (fetching && !data?.name) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!data)
    return showDelete ? (
      <Redirect to='/rotations'  />
    ) : (
      <ObjectNotFound type='rotation' />
    )

     //TLMT-3195 - Authorization
     //TLMT-3811
     const { groups, ready } = useSessionInfo()
     var secondaryActions=[]
     var rotUserCreate=false
 
     if (groups && groups.length > 0){
       if (groups.indexOf(GLASS_SUPER_ADMIN_EDIT_DELETE) !== -1 ){
         secondaryActions=[
          {
            label: 'Edit',
            icon: <Edit />,
            handleOnClick: () => setShowEdit(true),
          },
          {
            label: 'Delete',
            icon: <Delete />,
            handleOnClick: () => setShowDelete(true),
          },
          <QuerySetFavoriteButton
            key='secondary-action-favorite'
            type='rotation'
            id={props.rotationID}
          />,
        ]
         rotUserCreate=true
       }
   else if  (data.permission === "editor"){
     secondaryActions=[
      {
        label: 'Edit',
        icon: <Edit />,
        handleOnClick: () => setShowEdit(true),
      },
      <QuerySetFavoriteButton
        key='secondary-action-favorite'
        type='rotation'
        id={props.rotationID}
      />,
    ]
     rotUserCreate=true
   } else {
     secondaryActions=[
        <QuerySetFavoriteButton
        key='secondary-action-favorite'
        type='rotation'
        id={props.rotationID}
        />,
     ]
     rotUserCreate=false
   } 
  } else {
    secondaryActions=[
      <QuerySetFavoriteButton
      key='secondary-action-favorite'
      type='rotation'
      id={props.rotationID}
      />,
   ]
   rotUserCreate=false
  }
  //TLMT-3811

  return (
    <React.Fragment>
      {/* TLMT-3811 */}
      {rotUserCreate && <CreateFAB title='Add User' onClick={() => setShowAddUser(true)} />}
      {showAddUser && (
        <RotationAddUserDialog
          rotationID={props.rotationID}
          userIDs={data.userIDs}
          onClose={() => setShowAddUser(false)}
        />
      )}
      {showEdit && (
        <RotationEditDialog
          rotationID={props.rotationID}
          onClose={() => setShowEdit(false)}
        />
      )}
      {showDelete && (
        <RotationDeleteDialog
          rotationID={props.rotationID}
          onClose={() => setShowDelete(false)}
        />
      )}
      <DetailsPage
        avatar={<RotationAvatar />}
        title={data.name}
        subheader={handoffSummary(data)}
        details={data.description}
        //TLMT-3811
        pageContent={<React.Fragment> <GroupSupervisors resource="rotation" adgroup={data.adgroup} /> <br/> <RotationUserList rotationID={props.rotationID} /> </React.Fragment>}
        secondaryActions={secondaryActions}
      />
    </React.Fragment>
  )
}
